// Core imports
import './NotFound.scss';


export default function HomeView( React: any ) {
    return (
        <div>
            Page not found.
        </div>
    );
}
