/**
 * @description Converts degrees to radiants.
 * @param {number} deg Degrees.
 * @return {number} Equivalent radiants.
 */
export const degsToRads = ( deg: number ): number => (deg * Math.PI) / 180.0;

/**
 * @description Converts radiants to degrees.
 * @param {number} deg Radiants.
 * @return {number} Equivalent degrees.
 */
export const radsToDegs = ( rad: number ): number => rad * 180 / Math.PI;