import { ItemsGroup } from "../items/ItemsParams";
import { Layer } from "../../models/Layer";
import { ParamsHandler } from "../../classes/ParamsHandler";


export interface PageInterface {
    /**
     * @description Defines which background should be set when showing this component.
     */
    background: string;
    /**
     * @description Defines which blur should be seen on the background.
     */
    backgroundBlur: number;
    /**
     * @description Defines which color should be seen when the background is opaque or has transparent parts. Default: 'transparent'.
     */
    backgroundColor?: string;
    /**
     * @description Defines which opacity the background should have.
     */
    backgroundOpacity?: string;
    /**
     * @description Defines which background type should be used for this layer. Can be: 'image' | 'video'.
     */
    backgroundType?: string;
    /**
     * @description Array of items that should be displayed in this component. Should NOT be used in the UI.
     */
    items: ItemsGroup[];
    /**
     * @description Array of layers that should be displayed in this component.
     */
    layers: Layer[];
    /**
     * @description Page title.
     */
    title: string;
};

export class PageParams extends ParamsHandler {
    /**
     * PARAMS: Required variables used as params.
     */
    /**
     * @description Defines which background should be set when showing this component.
     */
    public background: string = '';
    /**
     * @description Defines which blur should be seen on the background.
     */
    public backgroundBlur: number = 0;
    /**
     * @description Defines which color should be seen when the background is opaque or has transparent parts. Default: 'transparent'.
     */
    public backgroundColor: string = 'transparent';
    /**
     * @description Defines which background type should be used for this layer. Can be: 'image' | 'video'.
     */
    public backgroundType: string = 'image';
    /**
     * @description Defines which opacity the background should have.
     */
    public backgroundOpacity: number = 1;
    /**
     * @description Array of items that should be displayed in this component. Should NOT be used in the UI.
     */
    public items: ItemsGroup[] = [];
    /**
     * @description Array of layers that should be displayed in this component.
     */
    public layers: Layer[] = [];
    /**
     * @description Page title.
     */
    public title: string = '';

    /**
     * STATES: Optional variables used in the UI as states or events handlers.
     */
    /**
     * @description State defining the background layer to render in this page.
     */
    public backgroundLayer?: Layer;
    /**
     * @description State defining the page's available width.
     */
    public width?: number;


    /**
     * CONSTRUCTOR AND HOOKS
     */

    constructor( params?: any ) {
        super();
        // Calling the validation after "super" to prevent the parent to overwrite initialized parameters.
        this.validate( params );
    }
}
