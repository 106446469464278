// Custom classes
import i18n from "./i18n";
import { Model } from "../classes/Model";


/**
 * @description Model that has a firstname and a lastname, and defines useful methods to handle them.
 */
export default class NamedModel extends Model {
    /**
     * VARIABLES
     */


    /**
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a Model that has a firstname and a lastname, and defines useful methods to handle them.
     * @param {any} item (Optional) Object containing data used to populate this instance.
     * @return {NamedModel} A new instance of this class.
     */
    constructor(
        item?: any
    ) {
        super( item );
    }


    /*
     * PUBLIC METHODS
     */

    /**
     * @description Returns if this instance's firstname or lastname contain the given parameter (it's NOT case sensitive).
     * @param {string} value Value that should be contained in this instance's firstname or lastname.
     * @return {boolean} True if this instance's firstname or lastname contain the given parameter, false if not.
     */
    public like( value: string ): boolean {
        const text: string = this.firstname.toLowerCase() + ' ' + this.lastname.toLowerCase();
        return text.indexOf( value.toLowerCase() ) !== -1;
    }

    /**
     * @description Prints this instance's firstname and lastname.
     * @return {string} Nicely formatted instance's firstname and lastname.
     */
    public print(): string {
        if ( this.firstname == '' && this.lastname == '' ) {
            return i18n.d.defaultName.t();
        }
        return this.firstname + ' ' + this.lastname;
    }
}
