import { AuthTranslations, ButtonsTranslations, ExerciseTranslations, GenericTranslations } from '../services/i18n/i18n';


export class Translation {
    /**
     * VARIABLES
     */
    /**
     * @description Index signature to allow any field to be used from a Translation's instance.
     */
    [key:string]: any;
    public en: string;
    public it: string;


    /**
     * CONSTRUCTOR AND HOOKS
     */
    constructor(
        data: any
    ) {
        this.en = data.en;
        this.it = data.it;
    }

    /**
     * @description (Same as this.t) Returns the correct string based on current locale. If undefined the default locale will be used.
     * @param {string} interps (Optional) Interpolations that should be applied to the resulting string. Example: [ "one" ] => "Test {0}" = "Test one".
     * @return {string} Correct translated string based on the locale or default locale. On failure returns: ''.
     */
    public get( interps?: string[] ): string {
        let result: string = '';
        if ( typeof this[ i18n.getLocale() ] === 'undefined' ) {
            if ( typeof this[ i18n.getDefaultLocale() ] === 'undefined' ) {
                return '';
            }
            result = this[ i18n.getDefaultLocale() ];
        } else {
            result = this[ i18n.getLocale() ];
        }
        if ( typeof interps !== 'undefined' ) {
            for ( let i = 0; i < interps.length; i++ ) {
                result = result.replace( '{'+i+'}', interps[i] );
            }
        }
        return result;
    }

    /**
     * @description (Same as this.get) Returns the correct string based on current locale. If undefined the default locale will be used.
     * @param {string} interps (Optional) Interpolations that should be applied to the resulting string. Example: [ "one" ] => "Test {0}" = "Test one".
     * @return {string} Correct translated string based on the locale or default locale. On failure returns: ''.
     */
    public t( interps?: string[] ): string {
        return this.get( interps );
    }
}


/**
 * @description Main translations singleton handling all translations divided in translations groups.
 */
export class I18n {
    /**
     * PRIVATE VARIABLES
     */
    /**
     * @description Default locale that will be used if a translation is not present for the requested locale.
     */
    private default: string = 'en';
    /**
     * @description I18n singleton instance: static global instance of this class.
     */
    private static instance: I18n;
    /**
     * @description Defines the full language identifier of the language currently in use.
     */
    private fullLocale: string = 'en-EN';
    /**
     * @description Defines the short language identifier of the language currently in use.
     */
    private locale: string = 'en';

    /**
     * VARIABLES
     */
    // Translations groups
    /**
     * @description Translations group for authentication UI elements. Includes SMS and normal authentication terminologies.
     */
    public auth: AuthTranslations = new AuthTranslations();
    /**
     * @description Translations group for buttons translations that could be used for any button in the application.
     */
    public buttons: ButtonsTranslations = new ButtonsTranslations();
    /**
     * @description Translations group for exercises specific translations.
     */
    public exercise: ExerciseTranslations = new ExerciseTranslations();
    /**
     * @description Generic translations group for any component that could be used multiple times all around the application.
     */
    public generic: GenericTranslations = new GenericTranslations();
    // Other variables
    /**
     * @description Default hardcoded reference to a generic translation group, used as fast typed reference. Hardcode this to your most used translation group.
     */
    public d: GenericTranslations = this.generic;


    /**
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates an instance of this main I18n singleton handling all translations divided in translations groups.
     * @return {I18n} An instance of this class.
     */
    constructor() {
        this.initGroups([
            this.auth,
            this.buttons,
            this.exercise,
            this.generic
        ]);
    }


    /**
     * STATIC METHODS
     */

    public static getInstance(): I18n {
        if ( !I18n.instance ) {
            I18n.instance = new I18n();
        }
        return I18n.instance;
    }


    /**
     * PRIVATE METHODS
     */

    /**
     * @description Initializes the TranslationGroup instance stored in the specified property of this singleton.
     * @param {any} group Property where the TranslationGroup instance is stored.
     */
    private initGroup( group: any ) {
        for ( let key in group ) {
            if (
                group.hasOwnProperty(key) &&
                typeof group[key] === 'object'
            ) {
                group[key] = new Translation( group[key] );
            }
        }
    }

    /**
     * @description Initializes the specified TranslationGroup instances stored in the specified properties of this singleton.
     * @param {any[]} groups Array of properties where the TranslationGroup instances are stored.
     */
    private initGroups( groups: any[] ) {
        for ( let i = 0; i < groups.length; i++ ) {
            this.initGroup( groups[i] );
        }
    }


    /**
     * PUBLIC METHODS
     */

    /**
     * @description Gets the short language identifier of the default language used in case of missing translations.
     * @return {string} Short version of the language identifier. Example: 'en'.
     */
    public getDefaultLocale(): string {
        return this.default;
    }

    /**
     * @description Gets the short language identifier of the language currently in use.
     * @return {string} Short version of the language identifier. Example: 'en'.
     */
    public getLocale(): string {
        return this.locale;
    }

    /**
     * @description Sets language identifiers of the default language that will be used as fallback for missing translations.
     * @param {string} fullLocale Standard version of the language identifier to set. Example: 'en-EN'.
     */
    public setDefaultLocale( fullLocale: string ) {
        this.default = fullLocale.split( '-' )[0];
    }

    /**
     * @description Sets language identifiers of the language currently in use.
     * @param {string} fullLocale Standard version of the language identifier to set. Example: 'en-EN'.
     */
    public setLocale( fullLocale: string ) {
        this.fullLocale = fullLocale;
        this.locale = fullLocale.split( '-' )[0];
    }

    /**
     * @description Returns the translation of a given object representing a multilingual text.
     * @param {any} textObject Object representing a multilingual text. Format: { it: string, en: string }.
     * @return {string} Correct translation of the given object, based on the current locale. On failure returns: ''.
     */
    public t( textObject: any ): string {
        if ( textObject.hasOwnProperty( this.locale ) ) {
            return textObject[ this.locale ];
        }
        return '';
    }
}


/**
 * @description Instance of the translations singleton handling all translations divided in translations groups.
 */
const i18n: I18n = I18n.getInstance();

export default i18n;
