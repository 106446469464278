import React, { useEffect } from 'react';

// Core imports
import NotFoundView from './NotFoundView';


const Home = ( props: any ) => {
  return NotFoundView( React );
}

export default Home;
