export default class TranslationGroup {
    /**
     * VARIABLES
     */
    /**
     * @description Index signature to allow any field to be used from a TranslationGroup's instance.
     */
    [key:string]: any;
    
    
    /**
     * CONSTRUCTOR AND HOOKS
     */
    constructor() {
    }


    /**
     * METHODS
     */

    /**
     * @description (Same as this.t) Gets the correct translation of a property of this instance.
     * @param {string} key Key of the property to translate.
     * @return {string} Translated property, based on the current locale.
     */
    public get( key: string ) {
        return this[ key ].get();
    }

    /**
     * @description (Same as this.get) Gets the correct translation of a property of this instance.
     * @param {string} key Key of the property to translate.
     * @return {string} Translated property, based on the current locale.
     */
    public t( key: string ) {
        return this.get( key );
    }
}
