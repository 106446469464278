import { Layer } from "../../models/Layer";
import { ParamsHandler } from "../../classes/ParamsHandler";
import Position from "../../interfaces/Position";
import Size from "../../interfaces/Size";


export interface LayerInterface {
    id: string;
    opacity?: number;
    source: string;
    position: Position;
    size: Size;
    type?: string;
};

export class LayerParams extends ParamsHandler {
    /**
     * PARAMS: Required variables used as params.
     */
    /**
     * @description Defines if this component should cover the full page.
     */
    public fullPage: boolean = false;
    /**
     * @description Defines which should be rendered in this component.
     */
    public layer: Layer = null;
    /**
     * @description Margin left that should be applied to the layer. Overridden if: {this.fullPage} is {true}.
     */
    public marginLeft: number = 0;
    /**
     * @description Margin top that should be applied to the layer. Overridden if: {this.fullPage} is {true}.
     */
    public marginTop: number = 0;

    /**
     * STATES: Optional variables used in the UI as states or events handlers.
     */


    /**
     * CONSTRUCTOR AND HOOKS
     */

    constructor( params?: any ) {
        super();
        // Calling the validation after "super" to prevent the parent to overwrite initialized parameters.
        this.validate( params );
    }
};
