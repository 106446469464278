import { Main } from './Main';
import React from 'react';
import ReactDOM from 'react-dom';

// Core imports
import 'normalize.css';
import './App.scss';

// Font awesome imports
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faAddressBook,
    faAddressCard,
    faIdBadge,
    faBriefcase,
    faHome,
    faLaptopHouse,
    faBuilding,
    faGlobe,
    faLaptopMedical,
    faPizzaSlice,
    faCamera, 
    faVideo,
    faCalendar,
    faWallet,
    faChess,
    faMusic,
    faKeyboard,
    faLaptop,
    faMobile,
    faTablet,
    faTasks,
    faClipboard,
    faClinicMedical,
    faCode,
    faCodeBranch
} from '@fortawesome/free-solid-svg-icons';
library.add(
    fab,
    faAddressBook,
    faAddressCard,
    faIdBadge,
    faBriefcase,
    faHome,
    faLaptopHouse,
    faBuilding,
    faGlobe,
    faLaptopMedical,
    faPizzaSlice,
    faCamera, 
    faVideo,
    faCalendar,
    faWallet,
    faChess,
    faMusic,
    faKeyboard,
    faLaptop,
    faMobile,
    faTablet,
    faTasks,
    faClipboard,
    faClinicMedical,
    faCode,
    faCodeBranch
);


export class App {
    constructor() {
        this.render();
    }

    private render(): void {
        ReactDOM.render( React.createElement( Main, { app: this } ),
            document.getElementById( "app" ) || document.createElement( "div" )
        );
    }
}

new App();