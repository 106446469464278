// Custom interfaces
import { ScreenSize } from "../../interfaces/ScreenSize";


/**
 * @description Singleton class built to share global data and methods about the screen.
 */
export default class ScreenService {
    /**
     * STATIC VARIABLES
     */
    /**
     * @description ScreenService singleton instance: static global instance of this service.
     */
    private static instance: ScreenService;

    /*
     * PRIVATE VARIABLES
     */
    /**
     * @description Screen sizes. Format: { width: number, height: number, half: { width: number, height: number } }.
     */
    private screen: ScreenSize;

    /**
     * VARIABLES
     */
    /**
     * @description Object storing all the screen's settings.
     */
    public settings: any = {
    };


    /**
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a new instance of this class.
     * @return {ScreenService} A new instance of this class.
     */
    constructor(
    ) {
        this.screen = {
            height: 0,
            width: 0,
            half: {
                height: 0,
                width: 0
            }
        };
    }


    /**
     * STATIC METHODS
     */

    public static getInstance(): ScreenService {
        if ( !ScreenService.instance ) {
            ScreenService.instance = new ScreenService();
        }
        return ScreenService.instance;
    }


    /**
     * PRIVATE METHODS
     */


    /*
     * METHODS
     */

    /**
     * @description Gets the screen's width and height.
     * @return {any} Format: { width: number, height: number, half: { width: number, height: number } }.
     */
    public getScreenSize(): ScreenSize {
        return this.screen;
    }

    /**
     * @description Gets the main window's width and height.
     * @return {any} Format: { width: number, height: number }.
     */
    public getWindowSize(): any {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    /**
     * @description Sets the screen's width and height.
     * @param { number } width Width to set.
     * @param { number } height Height to set.
     */
    public setScreenSize( width: number, height: number ) {
        this.screen.width = width;
        this.screen.height = height;
        this.screen.half.width = width / 2;
        this.screen.half.height = height / 2;
    }
}


/**
 * @description Ready to use instance of the singleton GlobalService.
 */
export const screen: ScreenService = ScreenService.getInstance();
