// Core imports
import './Item.scss';
import { ItemParams } from './ItemParams';

// Custom components handlers
import { getNestable } from '../nestables/Nestables';


export default function ItemView( React: any, params: ItemParams ) {
    let transform = 'translateZ(' + params.renderedPosition.z + 'px) rotateY(' + params.renderedRotation.y + 'deg)';
    if ( params.renderedRotation.x !== 0 ) {
        transform += ' rotateX(' + params.renderedRotation.x + 'deg)';
    }
    if ( params.renderedRotation.z !== 0 ) {
        transform += ' rotateZ(' + params.renderedRotation.z + 'deg)';
    }
    let zIndex: number = Math.floor( params.renderedPosition.z );
    if ( isNaN( zIndex ) || zIndex === Infinity ) {
        zIndex = 0;
    }
    const style: any = {
        left: params.renderedPosition.x,
        top: params.renderedPosition.y,
        transform,
        zIndex
    };
    if ( params.size.height !== null ) {
        style.height = params.size.height;
    }
    if ( params.size.width !== null ) {
        style.width = params.size.width;
    }

    const renderContent = () => {
        if ( typeof params.content === 'string' ) {
            return (
                <span>
                    { params.content }
                </span>
            );
        }
        return params.content.render( React );
    };

    return (
        <div
            id={ 'item-' + params.id }
            className="item"
            style={ style }
        >
            { renderContent() }
        </div>
    );
}
