import ApiBase from '../../classes/ApiBase';


/**
 * @description Class built to handle operations and requests which need the web medic portal, using RESTful APIs.
 */
export default class PortalService extends ApiBase {
    /**
     * STATIC VARIABLES
     */

    /**
     * VARIABLES
     */


    /**
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a new instance of this class.
     * @param {string} endpoint (Optional) Sets a default endpoint to add, after the root path, for any request sent from this service. Default; 'patients/.
     * @param {string} root (Optional) Root path of every endpoint handled by this instance. Default: keeps an hardcoded value.
     * @param {boolean} auth (Optional) Defines if every request sent by this instance should include an authentication token (true), or not (false). Default: true.
     * @param {any} defaultHeaders (Optional) Default headers to send in each request. Default: common headers for JSON requests.
     * @return {PortalService} A new instance of this class.
     */
    constructor(
        endpoint?: string,
        root?: string,
        auth?: boolean,
        defaultHeaders?: any
    ) {
        if ( typeof root === 'undefined' ) {
            root = './';
        }
        super( root, auth, defaultHeaders );
        if ( typeof endpoint !== 'undefined' ) {
            this.addEndpoint( endpoint );
        } else {
            this.addEndpoint( '' );
        }
    }


    /**
     * METHODS
     */
}

/**
 * @description Ready-to-use generic instance of PortalService with 'api/' endpoint added after the root API's path.
 */
export const portalService: PortalService = new PortalService( 'api/' );
