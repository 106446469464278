import React from 'react';

// Core imports
import { CardParams } from './CardParams';
import CardView from './CardView';


const Card = ( props: any ) => {

    const params: CardParams = new CardParams( props );

    params.children = props.children;

    return CardView( React, params );
}

export default Card;
