import { Content } from "../../models/Content";
import { ParamsHandler } from "../../classes/ParamsHandler";


export enum CardTypes {
    iOS = 'ios',
    Material = 'material',
    Metro = 'metro'
};


export class CardParams extends ParamsHandler {
    /**
     * PARAMS: Required variables used as params.
     */
    /**
     * @description Color category that should be used to style this component. Examples: 'primary' | 'secondary' | 'tertiary'.
     */
    public color: string = '';
    /**
     * @description Inner content that this component should display.
     */
    public content: Content | string | any = '';
    /**
     * @description Defines which identifier the root element should have for this component.
     */
    public footer: string = '';
    /**
     * @description Icon that should be displayed in this component.
     */
    public icon: string = '';
    /**
     * @description Size of the displayed icon in pixels. Default: 84.
     */
    public iconSize: number = 84;
    /**
     * @description Image that should be displayed in this component.
     */
    public image: string = '';
    /**
     * @description Defines which identifier the root element should have for this component.
     */
    public title: string = '';
    /**
     * @description Defines which identifier the root element should have for this component.
     */
    public type: CardTypes = CardTypes.Material;

    /**
     * STATES: Optional variables used in the UI as states or events handlers.
     */
    /**
     * @description Reference to the component's {props.children} object, containing the component's ready-to-use (JSX) content.
     */
    public children?: any;


    /**
     * CONSTRUCTOR AND HOOKS
     */

    constructor( params?: any ) {
        super();
        // Calling the validation after "super" to prevent the parent to overwrite initialized parameters.
        this.validate( params );
        // Formatting {this.content}
        if ( typeof params.content !== 'undefined' ) {
            if ( typeof params.content === 'string' ) {
                this.content = params.content;
            } else {
                this.content = new Content(
                    params.content.component,
                    params.content.content,
                    params.content.params
                );
            }
        }
    }
}
