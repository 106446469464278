// Custom classes
import TranslationGroup from '../../classes/TranslationGroup';

// Custom interfaces
import Translation from '../../interfaces/Translation';


export class AuthTranslations extends TranslationGroup {
    public codeError: Translation = {
        it: 'Si è verificato un errore nella verifica del codice, per favore controlla il codice e la tua connessione, oppure riprova più tardi. (Assicurati che il tuo medico abbia abilitato il tuo telefono)',
        en: 'An error occurred while verifying your code, please check the code and your connection, or try again later. (Ensure your doctor enabled your phone)'
    };
    public logout: Translation = {
        it: 'Esci',
        en: 'Logout'
    };
    public logoutMessage: Translation = {
        it: 'Confermando eliminerai tutti i dati relativi al tuo account da questo dispositivo, e non potrai più accedere a quei dati da qui finché non eseguirai nuovamente la conferma del tuo numero di telefono.',
        en: 'Confirming you\'ll erase all data about this account from this device, and you\'ll not be able to handle those data from here until you\'ll perform the phone number authentication again.'
    };
    public login: Translation = {
        it: 'Login',
        en: 'Login'
    };
    public messageError: Translation = {
        it: 'Si è verificato un errore nell\'invio del messaggio, per favore controlla il tuo numero, oppure riprova più tardi.',
        en: 'An error occurred while attempting to send you a message, please check your number, or try again later.'
    };
    public noPrefixMessage: Translation = {
        it: 'Il numero deve contenere il simbolo "+" seguito dal prefisso. Inserirlo e riprovare, per favore.',
        en: 'The phone number should contain the "+" symbol followed by the country code. Add it and try again, please.'
    };
    public noPrefixTitle: Translation = {
        it: 'Prefisso mancante',
        en: 'Country code missing'
    };
    public prefixReminder: Translation = {
        it: 'Ricorda di inserire il prefisso "+39"',
        en: 'Remember to include your country code'
    };
    public sendFooter: Translation = {
        it: 'Il tuo telefono non è stato abilitato da un medico?',
        en: 'Your phone haven\'t been enabled by a doctor?'
    };
    public sendInfoMessage: Translation = {
        it: 'Il tuo numero di telefono deve essere abilitato da un medico prima che tu possa utilizzare Medicapp, perché propone contenuti medici. Chiedi al tuo medico se può abilitarti, e inizia a rendere la tua terapia migliore!',
        en: 'Your phone number should be enabled by a doctor before you can use Medicapp, because it proposes medical contents. Ask to your medic to be enabled, and start making your therapy easier!'
    };
    public sendInfoTitle: Translation = {
        it: 'Hai già contattato il tuo medico?',
        en: 'Did you talked with your doctor?'
    };
    public sendIntroduction: Translation = {
        it: 'Manderemo un SMS al tuo numero per verificare la tua identità. Inseriscilo quando sei pronto, per favore.',
        en: 'We\'ll send an SMS to your phone number to verify your identity. Write it when you\'re ready, please.'
    };
    public sentIntroduction: Translation = {
        it: 'Abbiamo mandato un codice al tuo numero, per favore incollalo qui e sarai pronto a partire!',
        en: 'A code have been sent to your phone, please past it here and you\'ll be ready to go!'
    };
    public send: Translation = {
        it: 'Invia',
        en: 'Send'
    };
    public verify: Translation = {
        it: 'Verifica',
        en: 'Verify'
    };


    constructor() {
        super();
    }
};

export class ButtonsTranslations extends TranslationGroup {
    public back: Translation = {
        it: 'Indietro',
        en: 'Back'
    };
    public backTo: Translation = {
        it: 'Torna a:',
        en: 'Back to:'
    };
    public backToPolicy: Translation = {
        it: 'Torna alla Policy',
        en: 'Back to Policy'
    };
    public confirmAndGo: Translation = {
        it: 'Accetta e continua',
        en: 'Confirm and continue'
    };
    public later: Translation = {
        it: 'Più tardi',
        en: 'Later'
    };
    public next: Translation = {
        it: 'Avanti',
        en: 'Next'
    };
    public ok: Translation = {
        it: 'OK!',
        en: 'OK!'
    };
    public perform: Translation = {
        it: 'Esegui',
        en: 'Perform'
    };
    public play: Translation = {
        it: 'Play',
        en: 'Play'
    };
    public record: Translation = {
        it: 'Registra',
        en: 'Record'
    };
    public redo: Translation = {
        it: 'Rifai',
        en: 'Redo'
    };
    public save: Translation = {
        it: 'Salva',
        en: 'Save'
    };
    public send: Translation = {
        it: 'Invia',
        en: 'Send'
    };
    public sending: Translation = {
        it: 'Sto inviando...',
        en: 'Sending...'
    };
    public stop: Translation = {
        it: 'Stop',
        en: 'Stop'
    };
    public stopping: Translation = {
        it: 'Sto fermando...',
        en: 'Stopping...'
    };
    public view: Translation = {
        it: 'Vedi',
        en: 'View'
    };


    constructor() {
        super();
    }
};


export class ExerciseTranslations extends TranslationGroup {
    public allowBack: Translation = {
        it: 'Indietro',
        en: 'Back'
    };
    public allowDeny: Translation = {
        it: 'No grazie',
        en: 'No thanks'
    };
    public allowInfo: Translation = {
        it: 'Le tue registrazioni verranno viste solo dal tuo medico.',
        en: 'Only your doctor will see your recordings.'
    };
    public allowMessage: Translation = {
        it: 'Se non permetti all\'app di registrare non potrai seguire le terapie. Ti verrà chiesto nuovamente il permesso quando tornerai in questa pagina.',
        en: 'If you refuse permissions you\'ll not be able to complete your therapy. We\'ll ask again for permissions next time you\'ll open this page.'
    };
    public allowRequest: Translation = {
        it: 'Consenti all\'app di utilizzare la galleria e la videocamera per registrare gli esercizi.',
        en: 'Allow gallery and video recording permissions in order to record your exercises.'
    };
    public allowTitle: Translation = {
        it: 'Sei sicuro?',
        en: 'Are you sure?'
    };
    public bufferingTitle: Translation = {
        it: 'Ancora un attimo...',
        en: 'Wait a second...'
    };
    public bufferingMessage: Translation = {
        it: 'Il video si sta caricando. Riprova tra pochi secondi, per favore.',
        en: 'The video is loading. Wait few seconds and try again, please.'
    };
    public info: Translation = {
        it: 'Cliccando su Esegui potrai svolgere l’esercizio più volte, alla fine registrerai il video da inviare al tuo medico per la valutazione.',
        en: 'Tapping on Execute you\'ll perform the exercise multiple times, at the end you\'ll record a video that will be sent to your doctor.'
    };
    public loadingVideo: Translation = {
        it: 'Carico il video...',
        en: 'Loading video...'
    };
    /**
     * @description Interpolations: {0} => Permission type
     */
    public noPermissionsMessage: Translation = {
        it: 'Devi garantire i permessi per: {0}, per inviare gli esercizi. Vai nelle impostazioni e abilita i permessi per Medicapp.',
        en: 'You must grant the permission for: {0}, to send exercise\'s recordings. Go to your phone\'s settings and grant those permissions for Medicapp.'
    };
    public noPermissionsTitle: Translation = {
        it: 'Permessi insufficienti',
        en: 'Insufficient permissions'
    };
    public performExercise: Translation = {
        it: 'Esegui l\'esercizio',
        en: 'Perform exercise'
    };
    public recordedTitle: Translation = {
        it: 'Già fatto!',
        en: 'Already done!'
    };
    /**
     * @description Interpolations: {0} => Frequency
     */
    public recordedMessage: Translation = {
        it: 'Hai già inviato questo esercizio per oggi, basta una registrazione al giorno. Assicurati di averlo eseguito un totale di: {0} volte al giorno.',
        en: 'You already sent this exercise for today, is enough to record once every day. Be sure to perform this exercise for: {0} times every day.'
    };
    public sending: Translation = {
        it: 'Invio in corso. Potrebbe richiedere da 10 a 30 secondi, in base al video e alla velocità di connessione.',
        en: 'Sending. Could need from 10 to 30 seconds, depending on video length and connection speed.'
    };
    public sentTitle: Translation = {
        it: 'Inviato!',
        en: 'Sent!'
    };
    public sentMessage: Translation = {
        it: 'Registrazione inviata correttamente! Il tuo dottore lo revisionerà a breve.',
        en: 'Recording sent successfully! Your medic will evaluate it soon.'
    };


    constructor() {
        super();
    }
};


export class GenericTranslations extends TranslationGroup {
    public accept: Translation = {
        it: 'Accetta',
        en: 'Accept'
    };
    public acceptToContinue: Translation = {
        it: 'Leggi e accetta per continuare',
        en: 'Read and accept to continue'
    };
    public acceptMedicMessage: Translation = {
        it: 'Accettando dichiarerai di voler condividere con il medico i dati di tutte le terapie che ti assegnerà, i video che registrerai per quelle terapie, e i tuoi dati personali: nome e cognome.',
        en: 'Accepting you declare that you want to share with your medic all data about therapies that your medic will assign to you, all recordings you\'ll make for them, and your personal data: first name and last name.'
    };
    public afterEvaluationAfter: Translation = {
        it: 'Attendi notizie dal tuo medico, ti contatterà appena avrà controllato i dati.',
        en: 'Wait feedbacks from your medic, he will contact you after checking your data.'
    };
    public afterEvaluationBefore: Translation = {
        it: 'Ora puoi effettuare gli esercizi tramite i video che troverai qui!',
        en: 'Now you can perform the exercises with videos you\'ll find here!'
    };
    public application: Translation = {
        it: 'Applicazione',
        en: 'Application'
    };
    public cancel: Translation = {
        it: 'Annulla',
        en: 'Cancel'
    };
    public choose: Translation = {
        it: 'Scegli',
        en: 'Choose'
    };
    public code: Translation = {
        it: 'Codice',
        en: 'Code'
    };
    public contacts: Translation = {
        it: 'Contatti',
        en: 'Contacts'
    };
    public dailyFrequency: Translation = {
        it: 'Frequenza giornaliera',
        en: 'Daily frequency'
    };
    public date: Translation = {
        it: 'Data',
        en: 'Date'
    };
    public defaultName: Translation = {
        it: 'Paziente',
        en: 'Patient'
    };
    public defaultNewsDate: Translation = {
        it: '2 Giugno 2020',
        en: '2 June 2020'
    };
    public defaultNewsText: Translation = {
        it: 'Benvenuto in Medicapp! In questa sezione appariranno tutte le news relative all’applicazione.',
        en: 'Welcome to Medicapp! All the news related to the application will appear in this section.'
    };
    public doctor: Translation = {
        it: 'Dott.',
        en: 'Dr.'
    };
    public doctorAccepted: Translation = {
        it: 'Lo hai accettato come tuo medico.',
        en: 'Have been accepted as your medic.'
    };
    public doctorWaiting: Translation = {
        it: 'Ha chiesto di essere accettato come tuo medico.',
        en: 'Asked to be accepted as your medic.'
    };
    /**
     * @description Interpolations: {0} => Doctor's firstname and lastname.
     */
    public doctorWaitingMessage: Translation = {
        it: 'Dott. {0} ha chiesto di essere il tuo medico in Medicapp. Vuoi rispondere ora?',
        en: 'Doc. {0} asked to become your medic in Medicapp. Do you want to answer now?'
    };
    /**
     * @description Interpolations: {0} => Doctor's firstname and lastname.
     */
    public doctorWaitingTitle: Translation = {
        it: 'Dott. {0}',
        en: 'Doc. {0}'
    };
    public enterValidInput: Translation = {
        it: 'Per favore immetti un valore corretto per:',
        en: 'Please enter a valid value for:'
    };
    public error: Translation = {
        it: 'Errore',
        en: 'Error'
    };
    public evaluationAfter: Translation = {
        it: 'Scheda valutazione finale',
        en: 'Final evaluation'
    };
    public evaluationBefore: Translation = {
        it: 'Scheda valutazione iniziale',
        en: 'Initial evaluation'
    };
    public exercises: Translation = {
        it: 'Esercizi',
        en: 'Exercises'
    };
    public exercisesReminderMessage: Translation = {
        it: 'Ricorda che hai degli esercizi da registrare per oggi, buona guarigione!',
        en: 'Remember you have exercises you should record today, have a nice recovery!'
    };
    public exercisesReminderTitle: Translation = {
        it: 'Registra gli esercizi!',
        en: 'Record your exercises!'
    };
    public filter: Translation = {
        it: 'Filtra',
        en: 'Filter'
    };
    public firstname: Translation = {
        it: 'Nome',
        en: 'First name'
    };
    public from: Translation = {
        it: 'Da',
        en: 'From'
    };
    public hello: Translation = {
        it: 'Ciao',
        en: 'Hello'
    };
    public inactiveEvaluationMessage: Translation = {
        it: 'Hai già inviato questi dati, qui puoi solo ricontrollarli o scroprire come compilarli diversamente nella prossima scheda.',
        en: 'You did send those data already, here you can just check them or discover how you can edit things in the next sheet.'
    };
    public inactiveEvaluationTitle: Translation = {
        it: 'Già fatto!',
        en: 'Already done!'
    };
    public inactiveVideoMessage: Translation = {
        it: 'Prima di iniziare gli esercizi compila la scheda di valutazione qui sopra, per favore.',
        en: 'Fill the evaluation sheet up here before you start with any exercise, please.'
    };
    public inactiveVideoTitle: Translation = {
        it: 'Compila la scheda',
        en: 'Fill the sheet'
    };
    public inputErrorMessage: Translation = {
        it: 'Compila tutti i campi nel modo corretto e riprova, per favore.',
        en: 'Fill all required fields correctly and try again, please.'
    };
    public inputErrorTitle: Translation = {
        it: 'Valori errati',
        en: 'Wrong values'
    };
    public yes: Translation = {
        it: 'Si',
        en: 'Yes'
    };
    public lastname: Translation = {
        it: 'Cognome',
        en: 'Last name'
    };
    public legalDocuments: Translation = {
        it: 'Documenti Legali',
        en: 'Legal Documents'
    };
    public madeBy: Translation = {
        it: 'realizzata da',
        en: 'made by'
    };
    public news: Translation = {
        it: 'News',
        en: 'News'
    };
    public no: Translation = {
        it: 'No',
        en: 'No'
    };
    public noTherapies: Translation = {
        it: 'Non ci sono terapie al momento, se hai bisogno di cure contatta il tuo medico.',
        en: 'There are no therapies at the moment, if you need assistence contact your medic.'
    };
    public options: Translation = {
        it: 'Opzioni',
        en: 'Options'
    };
    public phone: Translation = {
        it: 'Telefono',
        en: 'Phone'
    };
    public policyNotAcceptedMessage: Translation = {
        it: 'Per usare questa applicazione dovresti accettare tutto ciò che è segnato come obbligatorio (*).',
        en: 'To use this application you should accept everything that is marked as required (*).'
    };
    public policyNotAcceptedTitle: Translation = {
        it: 'Accetta i termini obbligatori',
        en: 'Accept required terms'
    };
    public privacy: Translation = {
        it: 'Privacy Policy',
        en: 'Privacy Policy'
    };
    public profile: Translation = {
        it: 'Profilo',
        en: 'Profile'
    };
    public refresh: Translation = {
        it: 'Aggiorna',
        en: 'Refresh'
    };
    public reject: Translation = {
        it: 'Rifiuta',
        en: 'Reject'
    };
    public rejectMedicMessage: Translation = {
        it: 'Rifiutando impedirai al medico di avere accesso ai dati riguardanti le terapie che lui ti ha assegnato, e non potrà più assegnartele altre o interagire con te tramite questa applicazione.',
        en: 'Refusing you\'ll prevent your medic from viewing your data about therapies assigned by him/her, and your medic will no longer be able to assign other therapies to you or interact with you with this application.'
    };
    public responseErrorMessage: Translation = {
        it: 'C\'è stato un errore nell\'invio dei dati. Controlla la connessione o riprova più tardi, per favore.',
        en: 'We encountered an error while sending data. Check your connection or try again later, please.'
    };
    public responseErrorTitle: Translation = {
        it: 'Ops! Qualcosa non va...',
        en: 'Ops! Something went wrong...'
    };
    public responseSuccessMessage: Translation = {
        it: 'Complimenti! Hai inviato correttamente i tuoi dati!',
        en: 'Congratulations! Data successfully sent!'
    };
    public responseSuccessTitle: Translation = {
        it: 'Invio effettuato',
        en: 'Task completed'
    };
    public restartApp: Translation = {
        it: 'Verificare la connessione e riavviare l\'applicazione, per favore.',
        en: 'Check your internet connection and restart the application, please.'
    };
    public seconds: Translation = {
        it: 'Secondi',
        en: 'Seconds'
    };
    public select: Translation = {
        it: 'Seleziona',
        en: 'Select'
    };
    public step: Translation = {
        it: 'Step',
        en: 'Step'
    };
    public stepTitle: Translation = {
        it: 'Video step',
        en: 'Video step'
    };
    public sureQuestion: Translation = {
        it: 'Sei sicuro?',
        en: 'Are you sure?'
    };
    public sure: Translation = {
        it: 'Sono sicuro.',
        en: 'I\'m sure.'
    };
    public tapHere: Translation = {
        it: 'Premi qui',
        en: 'Tap here'
    };
    public therapy: Translation = {
        it: 'Terapia',
        en: 'Therapy'
    };
    public website: Translation = {
        it: 'Sito web',
        en: 'Website'
    };
    public version: Translation = {
        it: 'Versione',
        en: 'Version'
    };


    constructor() {
        super();
    }
};
