// Core imports
import './Layer.scss';
import { LayerParams } from './LayerParams';

// Custom models
import { Layer as LayerModel } from '../../models/Layer';


export default function LayerView( React: any, params: LayerParams ) {
    const layer: LayerModel = params.layer;

    let style: any = {
        left: layer.renderedPosition.x + 'px',
        top: layer.renderedPosition.y + 'px',
        zIndex: Math.floor( layer.renderedPosition.z )
    };

    if ( layer.blur !== 0 ) {
        style.filter = 'blur(' + layer.blur + 'px)';
    }

    let rotation: string = '';
    if ( layer.rotation.x !== 0 ) {
        rotation += ' rotateX(' + layer.rotation.x + 'deg)';
    }
    if ( layer.rotation.y !== 0 ) {
        rotation += ' rotateY(' + layer.rotation.y + 'deg)';
    }
    if ( layer.rotation.z !== 0 ) {
        rotation += ' rotateZ(' + layer.rotation.z + 'deg)';
    }
    if ( rotation != '' ) {
        rotation = rotation.substr( 1, rotation.length );
        style.transform = '' + rotation;
    }

    let renderContent = (): JSX.Element => {
        return (null);
    };
    if ( layer.type === 'video' ) {
        // Defines which percentage of width and height should be added to the video layer,
        // to ensure it will not show empty spaces while moving accordingly to the Scene's Camera instance.
        const offset: number = 0.1;
        style = {
            ...style,
            height: ( layer.size.height + ( layer.size.height * offset ) ) + 'px',
            marginLeft: ( layer.size.width * -1 * offset/2 ) + 'px',
            marginTop: ( layer.size.height * -1 * offset/2 ) + 'px',
            width: ( layer.size.width + ( layer.size.width * offset ) ) + 'px'
        };
        renderContent = (): JSX.Element => {
            return (
                <video
                    width="100%"
                    height="auto"
                    muted={ true }
                    autoPlay={ true }
                    controls={ false }
                    loop={ true }
                    style={{
                        backgroundColor: layer.backgroundColor,
                        opacity: layer.opacity
                    }}
                >
                    <source src={ layer.source } type="video/mp4" />
                    <source src={ layer.source } type="video/ogg" />
                </video>
            );
        };
    } else if ( layer.size.width === null || layer.size.height === null ) {
        style = {
            ...style,
            backgroundColor: layer.backgroundColor,
            height: ( layer.size.height === null ? 'auto' : layer.size.height + 'px' ),
            marginLeft: params.marginLeft + 'px',
            marginTop: params.marginTop + 'px',
            width: ( layer.size.width === null ? 'auto' : layer.size.width + 'px' )
        };
        renderContent = (): JSX.Element => {
            return (
                <img
                    alt={ layer.id }
                    src={ layer.source }
                    style={{
                        height: style.height,
                        opacity: layer.opacity,
                        width: style.width
                    }}
                />
            );
        };
    } else {
        style = {
            ...style,
            backgroundColor: layer.backgroundColor,
            backgroundImage: 'url(\'' + layer.source + '\')',
            backgroundPosition: layer.renderedPosition.x + 'px ' + layer.renderedPosition.y + 'px',
            height: layer.size.height + 'px',
            marginLeft: params.marginLeft + 'px',
            marginTop: params.marginTop + 'px',
            opacity: layer.opacity,
            width: layer.size.width + 'px'
        };
    }

    if ( layer.id === '' ) {
        <div
            className="layer"
            style={ style }
        >
            { renderContent() }
        </div>
    }
    return (
        <div
            id={ layer.id }
            className="layer"
            style={ style }
        >
            { renderContent() }
        </div>
    );
}
