import React from 'react';

// Core imports
import { ItemsParams } from './ItemsParams';
import ItemsView from './ItemsView';


const Items = ( props: any ) => {

    const params: ItemsParams = new ItemsParams( props );

    return ItemsView( React, params );
}

export default Items;
