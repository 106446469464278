import React from 'react';

// Core imports
import { ItemParams } from './ItemParams';
import ItemView from './ItemView';


const Item = ( props: any ) => {

    const params: ItemParams = new ItemParams( props );

    return ItemView( React, params );
}

export default Item;
