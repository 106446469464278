import { useState, useEffect } from 'react';


export const useDataHook = ( context: any, callback: string, params: any ) => {
    const [ response, setResponse ] = useState( null );

    const getData = async() => {
        const data: any = await context[callback]( ...params );
        setResponse( data );
    };

    useEffect(() => {
        getData();
    }, []);

    return response;
}
