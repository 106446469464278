// Core imports
import './Page.scss';
import { PageParams } from './PageParams';

// Custom components
import Items from '../items/Items';
import Layer from '../layer/Layer';
import Loading from '../../components/loading/Loading';

// Custom models
import { ItemsGroup } from '../items/ItemsParams';
import { Layer as LayerModel } from '../../models/Layer';


export default function PageView( React: any, params: PageParams ) {

    const items: ItemsGroup[] = params.items;
    const layers: LayerModel[] = params.layers;

    const renderItems = () => {
        if ( typeof items === 'undefined' || items.length === 0 ) {
            return (null);
        }

        return items.map( ( itemsGroup: ItemsGroup ) => {
            return( 
                <Items
                    key={ itemsGroup.id }
                    { ...itemsGroup }
                />
            );
        });
    };

    const renderLayers = () => {
        if ( typeof layers === 'undefined' || layers.length === 0 ) {
            return (null);
        }

        return layers.map( ( layer: LayerModel ) => {
            return( 
                <Layer
                    key={ layer.id }
                    layer={ layer }
                />
            );
        });
    };

    return (
        <div
            className="main-wrapper"
            style={{
                perspective: params.width
            }}
        >
            <div className="layers">
                { params.backgroundLayer !== null ?
                <Layer
                    fullPage={ true }
                    layer={ params.backgroundLayer }
                /> : null }
                { renderLayers() }
            </div>
            <div
                className="page"
            >
                { renderItems() }
            </div>
        </div>
    );
}
