import { Item } from "../../models/Item";
import { ParamsHandler } from "../../classes/ParamsHandler";
import Position from "../../interfaces/Position";
import Rotation from "../../interfaces/Rotation";

export interface ItemsGroup {
    id: string;
    items: Item[];
    position?: Position;
    rotation?: Rotation;
};

export class ItemsParams extends ParamsHandler {
    /**
     * PARAMS: Required variables used as params.
     */
    /**
     * @description Defines which identifier the root element should have for this component.
     */
    public id: string = '';
    /**
     * @description Defines which items this component should render.
     */
    public items: Item[] = [];
    /**
     * @description Defines where the component is positioned (absolute coordinates).
     */
    public position: Position = {
        x: 0,
        y: 0,
        z: 0
    };
    /**
     * @description Defines how the component is rotated (in degrees).
     */
    public rotation: Rotation = {
        x: 0,
        y: 0,
        z: 0
    };

    /**
     * STATES: Optional variables used in the UI as states or events handlers.
     */


    /**
     * CONSTRUCTOR AND HOOKS
     */

    constructor( params?: any ) {
        super();
        // Calling the validation after "super" to prevent the parent to overwrite initialized parameters.
        this.validate( params );
    }
}
