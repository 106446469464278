import { Item } from '../../models/Item';
import { Layer } from '../../models/Layer';
import { PageInterface } from '../../components/page/PageParams';
import PortalService from './Portal';


/**
 * @description Class built to handle operations and requests about pages.
 */
export default class PagesService extends PortalService {
    /**
     * STATIC VARIABLES
     */

    /**
     * VARIABLES
     */


    /**
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a new instance of this class.
     * @return {PagesService} A new instance of this class.
     */
    constructor(
    ) {
        super( 'api/pages/', './', false );
    }


    /**
     * METHODS
     */

    /**
     * @description Gets data for the specified single page.
     * @param {string} page Identifier of the page to fetch.
     * @return {Promise<PageInterface | null>} Specified page's data, formatted as PageInterface.
     */
    public async getPage( page: string ): Promise<PageInterface | null> {
        const data: any = await this.get( page + '.json' );
        if ( data === null ) {
            return null;
        }
        const pageData: PageInterface = {
            background: data.background || '',
            items: Item.toItemsGroups( data.items || [] ),
            backgroundColor: data.backgroundColor || 'transparent',
            backgroundOpacity: data.backgroundOpacity || 1,
            backgroundType: data.backgroundType || 'image',
            backgroundBlur: data.blur || 0,
            layers: Layer.toLayers( data.layers || [] ),
            title: data.title || ''
        };
        return pageData;
    }
}

/**
 * @description Ready-to-use generic instance of PagesService.
 */
export const pagesService: PagesService = new PagesService();
