// Custom interfaces
interface CameraThresholds {
    x: number;
    y: number;
};

// Custom services
import { global } from '../../services/global/Global';
import { screen } from '../../services/global/Screen';


/**
 * @description Class used to handle the 3D camera simulated with CSS.
 */
export class Camera {
    /*
     * VARIABLES
     */
    public x: number = 0;
    public y: number = 0;
    public z: number = 0;
    /**
     * @description Maximum angle (in degrees) that an object should adopt while visible by the camera.
     */
    private angle: number = 45;

    /*
     * STATIC VARIABLES
     */
    /**
     * @description Hardcoded thresholds percentage. Defines which is the maximum movement of the camera as percentages of width and height.
     */
    private static thresholds: CameraThresholds = {
        x: 0.5,
        y: 0.5
    };


    /*
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a new instance of Camera.
     * @param {number} angle (Optional) Maximum angle (in degrees) that an object should adopt while visible by the camera. Default: 45.
     * @return {Camera} Instance of Camera.
     */
    constructor(
        angle?: number 
    ) {
        if ( typeof angle !== 'undefined' ) {
            this.angle = angle;
        }
    }


    /*
     * STATIC METHODS
     */


    /*
     * PUBLIC METHODS
     */

    /**
     * @description Gets the maximum angle that an object should adopt while visible by the camera.
     * @return {number} Maximum angle in degrees.
     */
    public getAngle(): number {
        return this.angle;
    }

    /**
     * @description Updates the camera's position accordingly to the I/O (pointing device, accelerometer...) changes.
     */
    public update() {
        const screenSize: any = screen.getScreenSize();
        const cursor: any = global.getCursor();
        this.x = Math.max( Math.min( this.x + cursor.deltaX, screenSize.width*Camera.thresholds.x ), -1*Camera.thresholds.x*screenSize.width );
        this.y = Math.max( Math.min( this.y + cursor.deltaY, screenSize.height*Camera.thresholds.y ), -1*Camera.thresholds.y*screenSize.height );
    }


    /*
     * PRIVATE METHODS
     */
}
