// Core imports
import './Items.scss';
import { ItemsParams } from './ItemsParams';

// Custom components
import Item from '../item/Item';

// Custom models
import { Item as ItemModel } from '../../models/Item';


export default function ItemsView( React: any, params: ItemsParams ) {

    const style: any = {
        left: params.position.x,
        top: params.position.y
    };
    const items: ItemModel[] = params.items;

    const renderItems = () => {
        if ( typeof items === 'undefined' || items.length === 0 ) {
            return (null);
        }

        return items.map( ( itemModel: ItemModel ) => {
            return (
                <Item
                    key={ itemModel.id }
                    { ...itemModel }
                />
            );
        });
    };

    if ( params.id != '' ) {
        return (
            <div
                id={ params.id }
                className="items"
                style={ style }
            >
                { renderItems() }
            </div>
        );
    }

    return (
        <div
            className="items"
            style={ style }
        >
            { renderItems() }
        </div>
    );
}
