import * as React from 'react';
import { QueryParamProvider } from 'use-query-params';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { App } from './App';

// Custom interfaces
export interface IMainProps {
    app: App;
};

// Custom pages
import Home from './pages/home/Home';
import NotFound from './pages/not-found/NotFound';

// Custom services
import { screen } from './services/global/Screen';


export class Main extends React.Component<IMainProps, {}> {
    constructor( props: IMainProps ) {
        const windowSize: any = screen.getWindowSize();
        screen.setScreenSize( windowSize.width, windowSize.height );
        super( props );
    }

    public render(): JSX.Element {
        return (
            <div id="main">
                <Router>
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route component={NotFound} />
                        </Switch>
                    </QueryParamProvider>
                </Router>
            </div>
        );
    }
}
