import { Camera } from "../commons/scene/Camera";
import Position, { RawPosition } from "../interfaces/Position";
import Rotation from "../interfaces/Rotation";
import { screen } from '../services/global/Screen';
import { ScreenSize } from "../interfaces/ScreenSize";
import Size, { RawSize } from "../interfaces/Size";

interface ValidMeasure {
    value: number | null;
    squared: boolean;
};


/**
 * @description Class used to handle all the Scene renderable objects.
 */
export class Renderable {
    /**
     * VARIABLES
     */
    /**
     * @description Defines where the component is positioned (absolute coordinates).
     */
    public position: Position = {
        x: 0,
        y: 0,
        z: 0
    };
    /**
     * @description Defines where the component is positioned (coordinates relative to the current camera position).
     */
    public renderedPosition: Position = {
        x: 0,
        y: 0,
        z: 0
    };
    /**
     * @description Defines how the component is rotated (rotation relative to the current camera position and rotation).
     */
    public renderedRotation: Rotation = {
        x: 0,
        y: 0,
        z: 0
    };
    /**
     * @description Defines the starting rotation to add to {this.renderedRotation} at any rendering.
     */
    public rotation: Rotation = {
        x: 0,
        y: 0,
        z: 0
    };
    /**
     * @description Defines the height and width of this component.
     */
    public size: Size = {
        height: 0,
        width: 0
    };
    /**
     * @description Defines if this instance is visible because it's inside the camera's field of view.
     */
    public visible: boolean = false;


    /**
     * CONSTRUCTOR AND HOOKS
     */

    constructor( params?: any ) {
        this.position = Renderable.validatePosition( params.position );
        if ( typeof params.rotation !== 'undefined' ) {
            this.rotation = params.rotation;
        }
        this.size = Renderable.validateSize( params.size );
    }


    /**
     * STATIC METHODS
     */

    /**
     * @description Validates a given measure and returns the corresponding measure in pixels.
     * @param {any} value Value to validate, that defines the measure to convert in pixels.
     * @param {number} max Maximum size that could be reached with percentages (or: number of pixels equivalent to 100%).
     * @return {ValidMeasure} Measure in pixels and details (if should be squared or not). Format: { value: number | null, squared: boolean }.
     */
    private static validateMeasure( value: any, max: number ): ValidMeasure {
        if ( typeof value === 'string' ) {
            if ( value.indexOf( '%' ) !== -1 ) {
                let add: number = 0;
                value = value.replace( /\s/g, '' );
                if ( value.indexOf( '+' ) !== -1 ) {
                    add = parseFloat( value.split( '+' )[1] );
                } else if ( value.indexOf( '-' ) !== -1 ) {
                    add = parseFloat( value.split( '-' )[1] ) * -1;
                }
                return {
                    value: ( parseInt( value.replace( '%', '' ) ) / 100 ) * max + add,
                    squared: false
                }
            } else if ( value == 'auto' ) {
                return {
                    value: null,
                    squared: false
                };
            } else if ( value == 'squared' ) {
                return {
                    value: null,
                    squared: true
                };
            } else {
                return {
                    value: parseFloat( value ),
                    squared: false
                };
            }
        }
        return {
            value,
            squared: false
        };
    }

    /**
     * @description Validates a given position of interface RawPosition into a valid position of interface Position.
     * @param {RawPosition} rawPosition Position to validate.
     * @return {Position} Validated position of instance Position.
     */
    public static validatePosition( rawPosition: RawPosition ): Position {
        let position: Position = {
            x: 0, y: 0, z: 0
        };
        const screenSize: ScreenSize = screen.getScreenSize();
        if ( typeof rawPosition !== 'undefined' ) {
            position.x = this.validateMeasure( rawPosition.x, screenSize.width ).value;
            position.y = this.validateMeasure( rawPosition.y, screenSize.height ).value;
            if ( typeof rawPosition.z === 'string' ) {
                position.z = parseFloat( rawPosition.z );
            } else {
                position.z = rawPosition.z;
            }
        }
        return position;
    }

    /**
     * @description Validates a given size of interface RawSize into a valid size of interface Size.
     * @param {RawSize} rawSize Size to validate.
     * @return {Size} Validated size of instance Size.
     */
    public static validateSize( rawSize: RawSize ): Size {
        let size: Size = {
            width: 0, height: 0
        };
        const screenSize: ScreenSize = screen.getScreenSize();
        if ( typeof rawSize !== 'undefined' ) {
            const height: ValidMeasure = this.validateMeasure( rawSize.height, screenSize.height );
            const width: ValidMeasure = this.validateMeasure( rawSize.width, screenSize.width );
            size.height = height.value;
            size.width = width.value;
            if ( height.squared ) {
                size.height = size.width;
            }
            if ( width.squared ) {
                size.width = size.height;
            }
        }
        return size;
    }


    /**
     * PUBLIC METHODS
     */

    /**
     * @description Adds the given position values to the current absolute position {this.position}.
     * @param {Position} position Position values to add.
     */
    public addPosition( position: Position ) {
        this.position = {
            x: this.position.x + position.x,
            y: this.position.y + position.y,
            z: this.position.z + position.z
        };
    }

    /**
     * @description Adds the given rotation values to the current absolute rotation {this.rotation}.
     * @param {Rotation} rotation Rotation values to add.
     */
    public addRotation( rotation: Position ) {
        this.rotation = {
            x: this.rotation.x + rotation.x,
            y: this.rotation.y + rotation.y,
            z: this.rotation.z + rotation.z
        };
    }

    /**
     * @description Calculates if this instance is visible because it's inside the camera's field of view.
     * @param { Camera } camera Unique Scene instance's camera.
     * @return { boolean } True if visible, false if not.
     */
    public isVisible( camera: Camera ): boolean {
        const screenSize: ScreenSize = { ...screen.getScreenSize() };
        return !( this.renderedPosition.x > screenSize.width ||
            this.renderedPosition.x < ( this.size.width*-1 ) ||
            this.renderedPosition.y > screenSize.height ||
            this.renderedPosition.y < ( this.size.height*-1 ) ||
            this.renderedPosition.z < camera.z );
    }
}
