import { Camera } from "./Camera";
import { Item } from "../../models/Item";
import { ItemsGroup } from "../../components/items/ItemsParams";
import { Layer } from "../../models/Layer";


/**
 * @description Class used to handle the 3D scene simulated with CSS.
 */
export class Scene {
    /*
     * VARIABLES
     */
    /**
     * @description Layer separated from the other layers, to be rendered as an application's background.
     */
    public background: Layer = null;
    /**
     * @description Unique Scene instance's camera.
     */
    public camera: Camera;


    /*
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a new instance of Scene.
     * @param {number} angle (Optional) Maximum angle (in degrees) that an object should adopt while visible by the camera. Default: 45.
     * @return {Scene} Instance of Scene.
     */
    constructor(
        angle?: number
    ) {
        this.camera = new Camera( angle );
    }


    /*
     * STATIC METHODS
     */


    /*
     * PUBLIC METHODS
     */

    /**
     * @description Renders the current scene with the specified items and layers.
     * @param {ItemsGroup[]} itemsGroup ItemsGroup to render.
     * @param {Layer[]} layers Layers to render.
     */
    public render( itemsGroup: ItemsGroup[], layers: Layer[] ) {
        this.camera.update();
        for ( let g = 0; g < itemsGroup.length; g++ ) {
            for ( let i = 0; i < itemsGroup[g].items.length; i++ ) {
                const item: Item = itemsGroup[g].items[i];
                item.update( this.camera );
            }
        }
        for ( let i = 0; i < layers.length; i++ ) {
            const layer: Layer = layers[i];
            layer.update( this.camera );
        }
        if ( typeof this.background !== null ) {
            this.background.update( this.camera );
        }
    }


    /*
     * PRIVATE METHODS
     */
}
