// Core imports
import './Home.scss';

// Custom components
import Page from '../../components/page/Page';

// Custom interfaces
import { HomeParams } from './HomeParams';


export default function HomeView( React: any, params: HomeParams ) {
    return (
        <Page
            { ...params.page }
        />
    );
}
