import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, SizeProp } from '@fortawesome/fontawesome-svg-core';

// Core imports
import './Card.scss';
import { CardParams, CardTypes } from './CardParams';


export default function CardView( React: any, params: CardParams ) {

    const className: string = 'card ' + params.type + ' ' + params.color;

    const renderIcon = (): any => {
        if ( params.icon === '' ) {
            return (null);
        }
        return (
            <FontAwesomeIcon icon={ params.icon as IconName } style={{ fontSize: params.iconSize + 'px' }} />
        );
    };

    return (
        <div
            className={ className }
        >
            { params.image != '' ? <div className="image"><img src={ params.image } alt="card-image" /></div> : null }
            <header>
                <h3>
                    { params.title }
                </h3>
            </header>
            <div className="body">
                <div className="body-inner">
                    <div className="icon">
                        { renderIcon() }
                    </div>
                    { params.children ?
                    <div className="content">
                        { params.children }
                    </div> : null }
                </div>
            </div>
            <div className="footer">
                <p>
                    { params.footer }
                </p>
            </div>
        </div>
    );
}
