// Custom classes
import NamedModel from "../classes/NamedModel";

// Custom interfaces
import { Field } from '../interfaces/field';


/**
 * @description Model class representing a user (could also be the user currently authenticated in the application).
 */
export default class User extends NamedModel {
    /**
     * VARIABLES
     */
    // Mandatory parameters
    public _id: string;
    public createdAt: string;
    public updatedAt: string;
    // Optional parameters


    /**
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a new instance of this class.
     * @return {User} A new instance of this class.
     */
    constructor(
        item?: any
    ) {
        super( item );
    }


    /*
     * STATIC METHODS
     */

    /**
     * @description Returns an array of Field defining all informations about each field.
     * @return {Field[]} Array of Field.
     */
    static getFields(): Field[] {
        return [
            {
                name: '_id',
                type: 'string',
                primary: true
            },
            {
                name: 'createdAt',
                type: 'string',
            },
            {
                name: 'updatedAt',
                type: 'string',
            }
        ];
    }


    /*
     * PUBLIC METHODS
     */

    /**
     * @description Returns an array of Field defining all informations about each field.
     * @return {Field[]} Array of Field.
     */
    public getFields(): Field[] {
        return User.getFields();
    }

    /**
     * @description Casts a given value to a specified class (choosen between: ).
     * @param {any} value Value to cast.
     * @param {string} classname Name of the returned class.
     * @return {any} New instance of the specified class, constructed passing the given value.
     */
    public toClass( value: any, classname: string ): any {
        switch ( classname ) {
            case '':
                return null;
            default:
                return null;
        }
    }
}
