import { ItemsGroup } from "../../components/items/ItemsParams";
import { LayerInterface } from "../../components/layer/LayerParams";
import { PageInterface } from "../../components/page/PageParams";
import { ParamsHandler } from "../../classes/ParamsHandler";


export class HomeParams extends ParamsHandler {
    /**
     * PARAMS: Required variables used as params.
     */

    /**
     * STATES: Optional variables used in the UI as states or events handlers.
     */
    public page?: PageInterface;


    /**
     * CONSTRUCTOR AND HOOKS
     */

    constructor( params?: any ) {
        super();
        // Calling the validation after "super" to prevent the parent to overwrite initialized parameters.
        this.validate( params );
    }
}
