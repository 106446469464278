import { Content } from "../../models/Content";
import { ParamsHandler } from "../../classes/ParamsHandler";
import Position, { RawPosition } from "../../interfaces/Position";
import Rotation from "../../interfaces/Rotation";
import Size, { RawSize } from "../../interfaces/Size";


export interface ItemInterface {
    content: Content | string;
    id: string;
    position: RawPosition;
    size: RawSize;
    rotation?: Rotation;
    renderedPosition?: Position;
    renderedRotation?: Rotation;
};

export class ItemParams extends ParamsHandler {
    /**
     * PARAMS: Required variables used as params.
     */
    /**
     * @description Inner content that this component should display.
     */
    public content: Content | string = '';
    /**
     * @description Defines which identifier the root element should have for this component.
     */
    public id: string = '';
    /**
     * @description Defines where the component is positioned (absolute coordinates).
     */
    public position: Position = {
        x: 0,
        y: 0,
        z: 0
    };
    /**
     * @description Defines the rotation to add to the rendered rotation {this.renderedRotation} (absolute rotation).
     */
    public rotation: Rotation = {
        x: 0,
        y: 0,
        z: 0
    };
    /**
     * @description Defines the height and width of this component.
     */
    public size: Size = {
        height: 0,
        width: 0
    };

    /**
     * STATES: Optional variables used in the UI as states or events handlers.
     */
    /**
     * @description Handles the component's behaviour when the camera moves.
     */
    public onCameraMove?():void;
    /**
     * @description Defines where the component is positioned (coordinates relative to the current camera position).
     */
    public renderedPosition?: Position = {
        x: 0,
        y: 0,
        z: 0
    };
    /**
     * @description Defines how the component is rotated (rotation relative to the current camera position and rotation).
     */
    public renderedRotation?: Rotation = {
        x: 0,
        y: 0,
        z: 0
    };
    /**
     * @description Defines if this instance is visible because it's inside the camera's field of view.
     */
    public visible?: boolean = false;


    /**
     * CONSTRUCTOR AND HOOKS
     */

    constructor( params?: any ) {
        super();
        // Calling the validation after "super" to prevent the parent to overwrite initialized parameters.
        this.validate( params );
        // Store content
        if ( typeof params.content === 'string' ) {
            this.content = params.content;
        } else {
            this.content = new Content(
                params.content.component,
                params.content.content,
                params.content.params
            );
        }
    }
}
