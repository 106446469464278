// Custom models
import { Cursor } from "../../commons/scene/Cursor";
import { Scene } from "../../commons/scene/Scene";
import User from "../../models/User";


/**
 * @description Singleton class built to share global data and methods around the whole application.
 */
export default class GlobalService {
    /**
     * STATIC VARIABLES
     */
    /**
     * @description GlobalService singleton instance: static global instance of this service.
     */
    private static instance: GlobalService;

    /*
     * PRIVATE VARIABLES
     */
    /**
     * @description Instance of Cursor that handles mouse, touch or accelerometer events.
     */
    private cursor: Cursor;
    /**
     * @description String representing the current's page name as described in the navigation's properties.
     */
    private page: string = '';
    /**
     * @description Instance of Scene reprensenting the 3D scene simulated with CSS.
     */
    private scene: Scene;
    /**
     * @description Object reprensenting the user currently authenticated in the application.
     */
    private user: User | null = null;

    /**
     * VARIABLES
     */
    /**
     * @description Object storing all the application's settings.
     */
    public settings: any = {
    };


    /**
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a new instance of this class.
     * @return {GlobalService} A new instance of this class.
     */
    constructor(
    ) {
        this.scene = new Scene();
        this.cursor = new Cursor();
    }


    /**
     * STATIC METHODS
     */

    public static getInstance(): GlobalService {
        if ( !GlobalService.instance ) {
            GlobalService.instance = new GlobalService();
        }
        return GlobalService.instance;
    }


    /**
     * PRIVATE METHODS
     */


    /*
     * METHODS
     */

    /**
     * @description Gets the unique instance of Cursor that handles mouse, touch or accelerometer events.
     * @return {Cursor} Unique instance of Cursor.
     */
    public getCursor(): Cursor {
        return this.cursor;
    }

    /**
     * @description Gets the unique instance of Scene, reprensenting the 3D scene simulated with CSS.
     * @return {Scene} Unique instance of Scene.
     */
    public getScene(): Scene {
        return this.scene;
    }

    /**
     * @description Gets the user currently authenticated.
     * @return {User} Instance of the user currently authenticated.
     */
    public getUser() {
        return this.user;
    }

    /**
     * @description Returns if the current page has the specified name.
     * @param {string} name Name of the page to check, as described in the navigation's properties.
     * @return {boolean} True if the current page has the specified name, false if not.
     */
    public isPage( name: string ) {
        return this.page == name;
    }

    /**
     * @description Sets the current page's name.
     * @param {string | any} page Page name as described in the navigation's properties.
     */
    public setPage( page: string | any ) {
        if ( typeof page === 'object' ) {
            this.page = page.route.name
            return;
        }
        this.page = page;
    }

    /**
     * @description Sets the user currently authenticated.
     * @param {any} value Instance of User to set, or an object containing data for the User instantiation.
     */
    public setUser( value: any ) {
        if ( value instanceof User ) {
            this.user = value;
            return;
        }
        this.user = new User( value );
    }
}


/**
 * @description Ready to use instance of the singleton GlobalService.
 */
export const global: GlobalService = GlobalService.getInstance();
