import React, { useEffect, useState } from 'react';

// Core imports
import { LayerParams } from './LayerParams';
import LayerView from './LayerView';

// Custom interfaces
import { ScreenSize } from '../../interfaces/ScreenSize';

// Custom models
import { Layer as LayerModel } from '../../models/Layer';

// Custom services
import { screen } from '../../services/global/Screen';


const Layer = ( props: any ) => {

    const params: LayerParams = new LayerParams({ ...props });
    if ( params.fullPage ) {
        const layer: LayerModel = params.layer;
        const screenSize: ScreenSize = screen.getScreenSize();
        // Defines which percentage of width and height should be added to the video layer,
        // to ensure it will not show empty spaces while moving accordingly to the Scene's Camera instance.
        const offset: number = 0.1;
        if (
            layer.size.height === 0 ||
            layer.size.width === 0
        ) {
            if ( layer.size.height === 0 ) {
                layer.size.height = screenSize.height + ( screenSize.height * offset );
            }
            if ( layer.size.width === 0 ) {
                layer.size.width = screenSize.width + ( screenSize.width * offset );
            }
        }
        params.marginTop = screenSize.height * -1 * offset/2;
        params.marginLeft = screenSize.width * -1 * offset/2;
    }

    useEffect(() => {
    }, []);

    return LayerView( React, params );
}

export default Layer;
