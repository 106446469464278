import React, { useEffect, useState } from 'react';

// Core imports
import PageView from './PageView';

// Custom classes
import { PageParams } from './PageParams';

// Custom models
import { Cursor } from '../../commons/scene/Cursor';
import { Layer as LayerModel } from '../../models/Layer';
import { Scene } from '../../commons/scene/Scene';

// Custom services
import { global } from '../../services/global/Global';
import { screen } from '../../services/global/Screen';


const cursor: Cursor = global.getCursor();
const scene: Scene = global.getScene();

const Page = ( props: any ) => {

  const [ timestamp, setTimestamp ] = useState( 0 );

  const params: PageParams = new PageParams({ ...props });
  params.width = screen.getScreenSize().width;
  params.backgroundLayer = scene.background;

  const renderScene = () => {
    scene.render(
      params.items,
      params.layers
    );
    setTimestamp( new Date().getTime() );
    requestAnimationFrame( renderScene );
  };

  useEffect(() => {
    scene.background = new LayerModel({
      backgroundColor: params.backgroundColor,
      blur: params.backgroundBlur,
      id: 'app-bg',
      opacity: params.backgroundOpacity,
      position: {
        x: 0,
        y: 0,
        z: -800
      },
      source: params.background,
      type: params.backgroundType
    });
  
    renderScene();

    const updateCursor = ( event: any ) => {
      cursor.update( event.clientX, event.clientY );
    };

    window.addEventListener( 'mousemove', updateCursor );
    return () => window.removeEventListener( 'mousemove', updateCursor );
  }, []);

  return PageView( React, params );
}

export default Page;
