// Custom interfaces
import { Field } from '../interfaces/field';
export interface ModelResultFound {
    index: number;
    model: any;
};


/**
 * @description Model class used to handle a model with the automatic property acquisition system.
 */
export class Model {
    /*
     * STATIC VARIABLES
     */


    /*
     * VARIABLES
     */
    /**
     * @description Index signature to allow any field to be used from a Model's instance.
     */
    [key:string]: any;


    /*
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description  Creates a Model instance used to handle a model with the automatic property acquisition system.
     * @param {any} item (Optional) Object containing data used to populate this instance.
     * @return {Model} A new instance of this class.
     */
    constructor( item?: any ) {
        if ( typeof item !== 'undefined' && item !== null ) {
            this.create( item );
        }
    }


    /*
     * STATIC METHODS
     */

    /**
     * @description Finds a model in an array of models, returning the model and the index.
     * @param {any[]} list List of models where the search should be performed.
     * @param {string} key Key of model's instances to check.
     * @param {string} value Value that should be equal to.
     * @return {ModelResultFound} Found model and found index, or null and -1. Format: { model: any, index: number }.
     */
    public static find( list: any[], key: string, value: string ): ModelResultFound {
        for ( let i = 0; i < list.length; i++ ) {
            if (list[i].isEqual(key, value)) {
                return {
                    model: list[i],
                    index: i
                };
            }
        }
        return {
            model: null,
            index: -1
        };
    }

    /**
     * @description Creates an array of instances of this model, using the given object to get properties's values, and "this.fields" to map properties.
     * @param {any} items Source of the value of each property of this instance.
     * @return {any} Validated instances with all the assignable values saved correctly.
     */
    public static createArray( items: any[] ): any[] {
        if (
            typeof items === 'undefined' ||
            items === null ||
            !Array.isArray( items )
        ) {
            console.log( '[Model.createArray] Warning: invalid items given, an empty array will be returned.' );
            return [];
        }
        let result: any[] = [];
        for ( let i = 0; i < items.length; i++ ) {
            result.push(
                new this( items[i] )
            );
        }
        return result;
    }


    /*
     * PUBLIC METHODS
     */

    /**
     * @description Casts a given value to a specified type.
     * @param {any} value Value to cast.
     * @param {string} type Type that the value should assume.
     * @return {any} Casted value.
     */
    public cast( value: any, type: string ): any {
        switch ( type ) {
            case 'boolean':
                const validated: string = value.toString().toLowerCase().trim();
                return (validated === 'true' || validated === '1');
            case 'number':
                if ( value.indexOf('.') !== -1 ) {
                    return parseFloat(value);
                } else {
                    return parseInt(value);
                }
            case 'string':
                return value + '';
            default:
                return this.toClass( value, type );
        }
    }

    /**
     * @description Creates an instance of this model, using the given object to get properties's values, and this.fields to map properties.
     * @param {any} item Source of the value of each property of this instance.
     * @return {any} Validated instance with all the assignable values saved correctly.
     */
    create( item: any ): any {
        const fields = this.getFields();
        for ( let i = 0; i < fields.length; i++ ) {
            if ( item.hasOwnProperty(fields[i].name) ) {
                if ( typeof item[fields[i].name] !== fields[i].type ) {
                    if (
                        typeof fields[i].array !== 'undefined' &&
                        fields[i].array &&
                        Array.isArray(item[fields[i].name])
                    ) {
                        if ( typeof this[fields[i].name] === 'undefined' ) {
                            this[fields[i].name] = [];
                        }
                        for ( let a = 0; a < item[fields[i].name].length; a++ ) {
                            this[fields[i].name].push(this.cast(item[fields[i].name][a], fields[i].type));
                        }
                    } else {
                        this[fields[i].name] = this.cast(item[fields[i].name], fields[i].type);
                    }
                } else {
                    if (
                        typeof fields[i].array !== 'undefined' &&
                        fields[i].array &&
                        Array.isArray(item[fields[i].name])
                    ) {
                        if (typeof this[fields[i].name] === 'undefined') {
                            this[fields[i].name] = [];
                        }
                        for (let a = 0; a < item[fields[i].name].length; a++) {
                            this[fields[i].name].push(item[fields[i].name][a]);
                        }
                    } else {
                        this[fields[i].name] = item[fields[i].name];
                    }
                }
            } else {
                if (
                    typeof fields[i].binding !== 'undefined' &&
                    typeof fields[i].binding.mandatory !== 'undefined' &&
                    fields[i].binding.mandatory
                ) {
                    console.log('[Model.create] Warning: missing the mandatory parameter ' + fields[i].name + ' for the model: ', this);
                }
            }
        }
    }

    /**
     * @description Returns an array of Field defining all informations about each field.
     * @return {Field[]} Array of Field.
     */
    getFields(): Field[] {
        return [];
    }

    /**
     * @description Defines if this instance has a given property equal to a given value.
     * @param {string} key Key of this instance to check.
     * @param {string} value Value that should be equal to.
     * @return {boolean} True if equal, false if not.
     */
    isEqual( key: string, value: string ) {
        if (this.hasOwnProperty(key) && this[key] === value) {
            return true;
        }
    }

    /**
     * @description Casts a given value to a specified class (choosen from the models classes), should be overridden by child classes.
     * @param {any} value Value to cast.
     * @param {string} classname Name of the returned class.
     * @return {any} New instance of the specified class, constructed passing the given value.
     */
    toClass( value: any, classname: string ): any {
        return value;
    }


    /*
     * PRIVATE METHODS
     */
}
