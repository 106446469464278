// Custom interfaces
export interface CursorPercentages {
    x: number;
    y: number;
};
export interface CursorSpeed {
    x: number;
    y: number;
};
import Position from '../../interfaces/Position';

// Custom services
import { screen } from '../../services/global/Screen';


/**
 * @description Class used to handle mouse, touch or accelerometer events.
 */
export class Cursor {
    /*
     * VARIABLES
     */
    /**
     * @description Delta that defines how many pixels should the camera move in the X axis, due to the last cursor movement.
     */
    public deltaX: number = 0;
    /**
     * @description Delta that defines how many pixels should the camera move in the Y axis, due to the last cursor movement.
     */
    public deltaY: number = 0
    /**
     * @description Current cursor's horizontal position.
     */
    private x: number;
    /**
     * @description Current cursor's vertical position.
     */
    private y: number;
    /**
     * @description Depth value, defined by the scroll wheel or other pointing device's data.
     */
    private z: number;
    /**
     * @description Cursor speed, multiplied to the delta percentage calculate with the cursor position and the screen size.
     */
    private speed: CursorSpeed = {
        x: 12,
        y: 10
    };
    /**
     * @description Minimum delta that should trigger the camera movement, expressed in pixels.
     */
    private thresholds: CursorPercentages = {
        x: 0.5,
        y: 0.5
    };


    /*
     * CONSTRUCTOR AND HOOKS
     */

    constructor(
    ) {
    }


    /*
     * STATIC METHODS
     */


    /*
     * PUBLIC METHODS
     */

    /**
     * @description Returns the current cursor's position.
     * @return {Position} Last client's position detected where "z" represents the scroll or wheel. Format: { x: number, y: number, z: number }.
     */
    public getPosition(): Position {
        return {
            x: this.x,
            y: this.y,
            z: this.z
        };
    }

    /**
     * @description Updates the mouse position and delta, accordingly to the client's x and y given.
     * @param { number } x Client's X of the pointing device.
     * @param { number } y Client's Y of the pointing device.
     */
    public update( x: number, y: number ) {
        if ( typeof this.x === 'undefined' ) {
            this.x = x;
            this.y = y;
            return;
        }
        this.x = x;
        this.y = y;
        const half: any = { ...screen.getScreenSize() };
        half.height = half.height / 2;
        half.width = half.width / 2;
        const percentages: CursorPercentages = {
            x: ( half.width - this.x ) / half.width,
            y: ( half.height - this.y ) / half.height
        };
        this.deltaX = percentages.x * this.speed.x;
        this.deltaY = percentages.y * this.speed.y;
        if ( Math.abs( this.deltaX ) < this.thresholds.x ) {
            this.deltaX = 0;
        }
        if ( Math.abs( this.deltaY ) < this.thresholds.y ) {
            this.deltaY = 0;
        }
    }


    /*
     * PRIVATE METHODS
     */
}
