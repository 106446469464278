import { Camera } from '../commons/scene/Camera';
import { LayerInterface } from '../components/layer/LayerParams';
import Position, { RawPosition } from "../interfaces/Position";
import { Renderable } from './Renderable';
import Rotation from "../interfaces/Rotation";
import { screen } from '../services/global/Screen';
import { ScreenSize } from "../interfaces/ScreenSize";


export class Layer extends Renderable {
    /**
     * VARIABLES
     */
    /**
     * @description Defines which blur should be seen on this layer.
     */
    public blur: number = 0;
    /**
     * @description Defines which color should be seen when this instance has low opacity or has transparent parts in the source. Default: 'transparent'.
     */
    public backgroundColor: string = 'transparent';
    /**
     * @description Defines which identifier the root element should have for this component.
     */
    public id: string = '';
    /**
     * @description Opacity that should be applied to this instance on rendering. Default: 1.
     */
    public opacity: number = 1;
    /**
     * @description Defines which background source should be used for this layer.
     */
    public source: string = '';
    /**
     * @description Defines which background type should be used for this layer. Can be: 'image' | 'video'.
     */
    public type: string = 'image';

    /**
     * STATIC VARIABLES
     */
    /**
     * @description Hardcoded maximum z {this.position.z} that an instance of Layer should have.
     */
    public static maxZ: number = 1000;


    /**
     * CONSTRUCTOR AND HOOKS
     */

    constructor( params?: any ) {
        super( params );
        // Store backgroundColor
        if ( typeof params.backgroundColor !== 'undefined' ) {
            this.backgroundColor = params.backgroundColor;
        }
        // Store blur
        if ( typeof params.blur !== 'undefined' ) {
            this.blur = params.blur;
        }
        // Store id
        this.id = params.id;
        // Store opacity
        if ( typeof params.opacity !== 'undefined' ) {
            this.opacity = params.opacity;
        }
        // Store image
        this.source = params.source;
        // Store type
        this.type = params.type;
    }


    /**
     * STATIC METHODS
     */

    /**
     * @description Converts an array of layers data into an array of Item instances.
     * @param {LayerInterface[]} layersData Raw layers data, formatted as ItemInterface(s).
     * @param {Position | RawPosition} position (Optional) Position values to add to every item's starting position. Default: { x: 0, y: 0, z: 0 }.
     * @param {Rotation} rotation (Optional) Rotation values to add to every item's starting rotation. Default: { x: 0, y: 0, z: 0 }.
     * @return {Item[]} Array of valid Item instances.
     */
    public static toLayers( layersData: LayerInterface[], position?: Position | RawPosition, rotation?: Rotation ): Layer[] {
        const defaultValue: any = {
            x: 0, y: 0, z: 0
        };
        if ( typeof position === 'undefined' ) {
            position = { ...defaultValue };
        }
        if ( typeof rotation === 'undefined' ) {
            rotation = { ...defaultValue };
        }
        let layers: Layer[] = [];
        for ( let i = 0; i < layersData.length; i++ ) {
            const newLayer: Layer = new Layer( layersData[i] );
            newLayer.addPosition( this.validatePosition( position ) );
            newLayer.addRotation( rotation );
            layers.push( newLayer );
        }
        return layers;
    }


    /**
     * PUBLIC METHODS
     */

    /**
     * @description Gets the depth factor that should be simulated. Calculated using this instance's z {this.position.z} and the given camera z.
     * @param { Camera | any } camera (Optional) Unique Scene instance's camera. Default: { z: 0 } (the camera z {camera.x} will not be considered).
     * @return {number} Depth factor, it's always <= 1 and >= 0. Greater z {this.position.z} means lower depth factor.
     */
    public getDepth( camera?: Camera | any ): number {
        if ( typeof camera === 'undefined' ) {
            camera = { z: 0 };
        }
        const z: number = camera.z - this.position.z;
        return 1 - Math.max( Math.min( z / Layer.maxZ, 1 ), 0 );
    }

    /**
     * @description Updates this instance's render data (primarly: position and rotation).
     * @param { Camera } camera Unique Scene instance's camera.
     */
    public update( camera: Camera ) {
        const screenSize: ScreenSize = screen.getScreenSize();
        const depth: number = this.getDepth( camera );
        if ( depth < 0 ) {
            this.visible = false;
            return;
        }
        const x: number = this.position.x + ( camera.x / screenSize.width ) * screenSize.width * depth / 10;
        const y: number = this.position.y + ( camera.y / screenSize.height ) * screenSize.height * depth / 10;
        this.renderedPosition = {
            x, y,
            z: this.position.z
        };
    }
}
