import React, { useEffect } from 'react';

// Core imports
import HomeView from './HomeView';

// Custom classes
import { HomeParams } from './HomeParams';

// Custom interfaces
import { PageInterface } from '../../components/page/PageParams';

// Custom services
import { pagesService } from '../../services/api/Pages';

// Custom hooks
import { useDataHook } from '../../commons/data/data-hook';


const Home = ( props: any ) => {

  const params: HomeParams = new HomeParams( props.match.params );

  const page: PageInterface | null = useDataHook( pagesService, 'getPage', [ 'home' ] );

  if ( page === null ) {
    return (null);
  }

  params.page = page;
  
  /*
  const screenSize: any = screen.getScreenSize();
  params.background = './src/resources/img/space-dark.jpg';
  const items: ItemInterface[] = [];
  const size: number = Math.floor( screenSize.width / 10 );
  let itemsCount = 10;
  for ( let i = 0; i < itemsCount; i++ ) {
    const x = i * size;
    items.push({
      content: '' + i,
      id: '' + i,
      position: {
        x: x,
        y: 100,
        z: 0
      },
      size: {
        height: size,
        width: size
      }
    });
  }
  params.items = [
    Item.toItemsGroup(
      items,
      'header-items',
      {
        x: 0,
        y: size * -0.3,
        z: 50
      },
      {
        x: -15,
        y: 0,
        z: 0
      }
    ),
    Item.toItemsGroup(
      items,
      'body-items',
      {
        x: 0,
        y: size * 0.3,
        z: 0
      }
    )
  ];
  params.layers = [];
  */

  return HomeView( React, params );
}

export default Home;
