// Custom components handlers
import { getNestable } from '../components/nestables/Nestables';


export class Content {
    /**
     * VARIABLES
     */
    /**
     * @description Content of the component to display.
     */
    public content: Content | string;


    /**
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a new instance of Content used to store the content that should be displayed inside an item (or any kind of container).
     * @param {string} component Name of the component to display.
     * @param {string} content Content of the component to display.
     * @param {any} params (Optional) Parameters to pass to the component.
     * @return {Content} Instance of Content.
     */
    constructor(
        public component: string,
        content: Content | string,
        public params?: any
    ) {
        if ( typeof content === 'undefined' ) {
            return;
        }
        if ( typeof content === 'string' ) {
            this.content = content;
        } else {
            this.content = new Content(
                content.component,
                content.content,
                content.params
            );
        }
    }


    /**
     * STATIC METHODS
     */


    /**
     * PUBLIC METHODS
     */

    public render( React: any ): any {
        const renderContent = () => {
            if ( typeof this.content === 'undefined' ) {
                return (null);
            }
            if ( typeof this.content === 'string' ) {
                return (
                    <span>
                        { this.content }
                    </span>
                );
            }
            return this.content.render( React );
        }
        const ComponentTag: any = getNestable( this.component );
        const params: any = { ...this.params, content: this.content };
        return (
            <ComponentTag
                { ...params }
            >
                { renderContent() }
            </ComponentTag>
        );
    }
}
