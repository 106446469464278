/**
 * @description Class used as a parent class to automatically initialize and validate parameters for any component.
 */
export class ParamsHandler {
    /**
     * VARIABLES
     */

    /**
     * @description Index signature to allow any field to be used from a ParamsHandler's instance.
     */
    [key:string]: any;


    /**
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a ComponentParams instance, used as a parent class to automatically initialize and validate parameters for any component.
     * @param {any} params (Optional) Parameters to validate. If undefined the validation will not be performed.
     * @return {ParamsHandler} Instance of this class.
     */
    constructor( params?: any ) {
        if ( typeof params === 'undefined' ) {
            return;
        }
        this.validate();
    }


    /**
     * METHODS
     */

    /**
     * @description Performs the parameters validation for the component. Call this method after "super()" to prevent initialized parameter replacing.
     * @param {any} params (Optional) Parameters to validate. If invalid or empty, every value will be set as null or empty function. Pre-assigned values will not be changed.
     */
    public validate( params?: any ) {
        if ( typeof params === 'undefined' ) {
            params = {};
        }
        for ( let key in this ) {
            if ( this.hasOwnProperty(key) ) {
                if (
                    params.hasOwnProperty(key) &&
                    typeof this[key] === typeof params[key]
                ) {
                    this[key] = params[key];
                } else {
                    if ( typeof this[key] === 'undefined' ) {
                        const ref: any = this[key];
                        if ( ref instanceof Function ) {
                            const value: any = () => {};
                            this[key] = value;
                        } else {
                            this[key] = null;
                        }
                    }
                }
            }
        }
    }
}
