// Custom interfaces
import AppToken from '../interfaces/AppToken';


/**
 * @description Class built to handle any operation and request that should be made using RESTful APIs.
 */
export default class ApiBase {
    /**
     * STATIC VARIABLES
     */
    /**
     * @description Token stored as a static variable to use it in every API based class, allowing authorizations child classes to set it.
     */
    public static token: AppToken = {
        createdAt: 0,
        expiresIn: 0,
        expirationTime: 0,
        firebaseUser: { getIdToken: async() => { return ApiBase.token.value; } },
        id: '',
        key: 'Authorization',
        refreshToken: '',
        value: ''
    };


    /**
     * VARIABLES
     */
    /**
     * @description Defines if every request sent by this instance should include an authentication token (true), or not (false).
     */
    private auth: boolean = true;
    /**
     * @description Defines which value should be returned in case of invalid response found.
     */
    private onInvalid: any = null;
    /**
     * @description Defines which is the root path of any API handled by this instance.
     */
    private root: string;
    /**
     * @description Defines the default headers that should be added to any request sent by this instance.
     */
    private defaultHeaders: any = {
        'Content-Type': 'application/json'
    };


    /**
     * CONSTRUCTOR AND HOOKS
     */

    /**
     * @description Creates a new instance of this class.
     * @param {string} root Root path of every endpoint handled by this instance.
     * @param {boolean} auth (Optional) Defines if every request sent by this instance should include an authentication token (true), or not (false). Default: true.
     * @param {any} defaultHeaders (Optional) Default headers to send in each request. Default: common headers for JSON requests.
     * @return {ApiBase} A new instance of this class.
     */
    constructor(
        root: string,
        auth?: boolean,
        defaultHeaders?: any
    ) {
        this.root = root;
        if ( typeof auth !== 'undefined' ) {
            this.auth = auth;
        }
        if ( typeof defaultHeaders !== 'undefined' ) {
            this.defaultHeaders = defaultHeaders;
        }
    }


    /**
     * STATIC METHODS
     */

    public static async getToken(): Promise<string> {
        return await ApiBase.token.firebaseUser.getIdToken();
    }


    /**
     * PRIVATE METHODS
     */

    /**
     * @description Gets the url encoded version of the given object.
     * @param {any} parameters (Optional) Parameters to encode. Returns '' if not defined.
     * @return {string} Url encoded version of the parameters given. On failure returns: ''.
     */
    private encode( params?: any ): string {
        if ( typeof params === 'undefined' ) {
            return '';
        }
        let query_string: string = '?';
        for ( const key in params ) {
            if ( params.hasOwnProperty(key) ) {
                query_string += key + '=' + params[key] + '&';
            }
        }
        if ( query_string.endsWith( '&' ) ) {
            query_string = query_string.substring( 0, query_string.length - 1 );
        }
        return query_string;
    }

    /**
     * @description Checks if a given response is valid.
     * @param {any} response Response sent back by the server.
     * @return {boolean} True if valid, false if not.
     */
    private isValid( response: any ): boolean {
        if (
            typeof response !== 'undefined' &&
            response !== null &&
            typeof response.message === 'undefined'
        ) {
            return true;
        }
        return false;
    }

    /**
     * @description Validates the given response data and handles all invalid responses errors.
     * @param {any} data Object containing the response to a request sent to the server.
     * @return {any} Validated response. If invalid: returns an empty object: {}.
     */
    private async validate( data: any ): Promise<any> {
        if (
            typeof data === 'undefined' ||
            data === null
        ) {
            // Ensures that empty responses will be considered as valid responses
            data = {};
        } else {
            try {
                data = await data.json();
            } catch( error ) {
                if ( error.toString().indexOf( 'SyntaxError: JSON Parse error: Unexpected EOF' ) !== -1 ) {
                    return {};
                }
                throw error;
            }
        }
        if ( !this.isValid( data ) ) {
            console.log( '[ApiBase.validate] Warning: an invalid response have been sent back from the server: ', data );
            return this.onInvalid;
        }
        return data;
    }


    /**
     * PUBLIC METHODS
     */

    /**
     * @description Sets a custom path as a default enpoint, always added after the root path of any request.
     * @param {string} endpoint Endpoint path to set for every request.
     */
    public addEndpoint( endpoint: string ) {
        this.root = this.root + endpoint;
    }

    /**
     * @description Performs a get request to the specified endpoint.
     * @param {string} endpoint Endpoint used to build the url of the request.
     * @param {any} query (Optional) Object containing all parameters that should be added to the query string of this request.
     * @param {any} addHeaders (Optional) Headers to add to this request.
     * @return {Promise<any>} Awaitable response containig all data sent back from the server.
     */
    async get( endpoint: string, query?: any, addHeaders?: any ): Promise<any> {
        let query_string: string = this.encode( query );
        let headers: any = await this.getHeaders();
        headers = {
            ...headers,
            ...addHeaders
        };
        let response: any;
        try {
            const request: any = await fetch( this.root + endpoint + query_string, {
                method: 'GET',
                headers: headers
            });
            response = await this.validate( request );
        } catch ( error ) {
            this.handleError( 'get', error );
            throw error;
        }
        return response;
    }

    /**
     * @description Gets headers added to any request sent by this instance.
     */
    public async getHeaders(): Promise<any> {
        let headers: any = { ...this.defaultHeaders };
        if ( this.auth ) {
            headers[ ApiBase.token.key ] = await ApiBase.getToken();
        }
        return headers;
    }

    /**
     * @description Gets the root path of any API handled by this instance.
     */
    public getRoot(): string {
        return this.root;
    }

    private handleError( method: string, error: any ) {
        console.log( '[ApiBase.' + method + '] Error: ', error );
    }

    /**
     * @description Gets if every request sent by this instance should include an authentication token (true), or not (false).
     */
    public isAuth(): boolean {
        return this.auth;
    }

    /**
     * @description Performs a post request to the specified endpoint.
     * @param {string} endpoint Endpoint used to build the url of the request.
     * @param {any} body (Optional) Object that will be attached to the request as body.
     * @param {any} query (Optional) Object containing all parameters that should be added to the query string of this request.
     * @param {any} addHeaders (Optional) Headers to add to this request.
     * @param {boolean} stringify (Optional) Defines if the body should be stringified before sending the request.
     * @return {Promise<any>} Awaitable response containig all data sent back from the server.
     */
    async post( endpoint: string, body?: any, query?: any, addHeaders?: any, stringify?: boolean ): Promise<any> {
        if ( typeof stringify === 'undefined' ) {
            stringify = true;
        }
        let query_string: string = this.encode( query );
        let response: any;
        let validBody: any;
        if ( typeof body === 'undefined' ) {
            body = {};
        }
        try {
            let headers: any = await this.getHeaders();
            headers = {
                ...headers,
                ...addHeaders
            };
            if ( stringify ) {
                validBody = JSON.stringify( body );
            } else {
                validBody = body;
            }
            const request: any = await fetch( this.root + endpoint + query_string, {
                method: 'POST',
                headers: headers,
                body: validBody
            });
            response = await this.validate( request );
        } catch ( error ) {
            this.handleError( 'post', error );
            throw error;
        }
        return response;
    }

    /**
     * @description Performs a put request to the specified endpoint.
     * @param {string} endpoint Endpoint used to build the url of the request.
     * @param {any} body (Optional) Object that will be attached to the request as body.
     * @param {any} query (Optional) Object containing all parameters that should be added to the query string of this request.
     * @param {any} addHeaders (Optional) Headers to add to this request.
     * @return {Promise<any>} Awaitable response containig all data sent back from the server.
     */
    async put( endpoint: string, body?: any, query?: any, addHeaders?: any ): Promise<any> {
        let query_string: string = this.encode( query );
        let response;
        if ( typeof body === 'undefined' ) {
            body = {};
        }
        try {
            let headers: any = await this.getHeaders();
            headers = {
                ...headers,
                ...addHeaders
            };
            const request: any = await fetch( this.root + endpoint + query_string, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify( body )
            });
            response = await this.validate( request );
        } catch ( error ) {
            this.handleError( 'put', error );
            throw error;
        }
        return response;
    }

    /**
     * @description Sets the default headers for this instance.
     * @param {any} headers Headers to set.
     */
    public setDefaultHeaders( headers: any ) {
        if ( typeof headers === 'undefined' ) {
            return;
        }
        this.defaultHeaders = headers;
    }
}
