import Card from "../card/Card";


export const Nestables: any = {
    Card: Card
};


/**
 * @description Returns the nestable React component named as specified.
 * @param {string} component Component name.
 * @return {any} Specified React component.
 */
export const getNestable = ( component: string ) => {
    return Nestables[ component ];
};
