import { Content } from './Content';
import { Camera } from '../commons/scene/Camera';
import { degsToRads, radsToDegs } from "../commons/utils/AnglesUtilities";
import { ItemInterface } from '../components/item/ItemParams';
import { ItemsGroup } from '../components/items/ItemsParams';
import Position, { RawPosition } from "../interfaces/Position";
import { Renderable } from './Renderable';
import Rotation from "../interfaces/Rotation";
import { screen } from '../services/global/Screen';
import { ScreenSize } from "../interfaces/ScreenSize";


export class Item extends Renderable {
    /**
     * VARIABLES
     */
    /**
     * @description Inner content that this component should display.
     */
    public content: Content | string = '';
    /**
     * @description Defines which identifier the root element should have for this component.
     */
    public id: string = '';


    /**
     * CONSTRUCTOR AND HOOKS
     */

    constructor( params?: any ) {
        super( params );
        // Store content
        if ( typeof params.content === 'string' ) {
            this.content = params.content;
        } else {
            this.content = new Content(
                params.content.component,
                params.content.content,
                params.content.params
            );
        }
        // Store id
        this.id = params.id;
    }


    /**
     * STATIC METHODS
     */

    /**
     * @description Converts an array of items data into an array of Item instances.
     * @param {ItemInterface[]} itemsData Raw items data, formatted as ItemInterface(s).
     * @param {Position | RawPosition} position (Optional) Position values to add to every item's starting position. Default: { x: 0, y: 0, z: 0 }.
     * @param {Rotation} rotation (Optional) Rotation values to add to every item's starting rotation. Default: { x: 0, y: 0, z: 0 }.
     * @return {Item[]} Array of valid Item instances.
     */
    public static toItems( itemsData: ItemInterface[], position?: Position | RawPosition, rotation?: Rotation ): Item[] {
        const defaultValue: any = {
            x: 0, y: 0, z: 0
        };
        if ( typeof position === 'undefined' ) {
            position = { ...defaultValue };
        }
        if ( typeof rotation === 'undefined' ) {
            rotation = { ...defaultValue };
        }
        let items: Item[] = [];
        for ( let i = 0; i < itemsData.length; i++ ) {
            const newItem: Item = new Item( itemsData[i] );
            newItem.addPosition( this.validatePosition( position ) );
            newItem.addRotation( rotation );
            items.push( newItem );
        }
        return items;
    }

    /**
     * @description Converts an array of items data into an items group of interface ItemsInterface.
     * @param {ItemInterface[]} itemsData Raw items data, formatted as ItemInterface(s).
     * @param {string} id (Optional) Which identifier the ItemsGroup's root should have. Default: ''.
     * @param {Position} position (Optional) How the ItemsGroup is positioned (absolute coordinates). Default: { x: 0, y: 0, z: 0 }.
     * @param {Rotation} rotation (Optional) How the component is rotated (in degrees). Default: { x: 0, y: 0, z: 0 }.
     * @return {ItemsGroup} Validate ItemsGroup object.
     */
    public static toItemsGroup( itemsData: ItemInterface[], id?: string, position?: Position, rotation?: Rotation ): ItemsGroup {
        const defaultValue: any = {
            x: 0, y: 0, z: 0
        };
        return {
            id: id || '',
            items: Item.toItems( itemsData, position, rotation ),
            position: position || defaultValue,
            rotation: rotation || defaultValue
        };
    }

    /**
     * @description Converts an array of items group data into an array of valid items group of interface ItemsInterface.
     * @param {any[]} groupsData Raw items group data.
     * @return {ItemsGroup[]} Validate ItemsGroup array.
     */
    public static toItemsGroups( groupsData: any[] ): ItemsGroup[] {
        let itemsGroups: ItemsGroup[] = [];
        for ( let i = 0; i < groupsData.length; i++ ) {
            itemsGroups.push( Item.toItemsGroup(
                groupsData[i].items,
                groupsData[i].id,
                groupsData[i].position,
                groupsData[i].rotation
            ));
        }
        return itemsGroups;
    }


    /**
     * PUBLIC METHODS
     */

    /**
     * @description Gets the horizontal proportion accordingly to half screen width (divides the width in 2 quadrants).
     * @param { number } x (Optional) Rendered x, should be passed only if {renderedPosition.x} is not updated yet. Default: {renderedPosition.x}.
     * @return {number} Percentage of the current x position in relation to half screen width. Example: width=100, x=100 returns=-1, x=0 returns=1.
     */
    public getProportionX( x?: number ): number {
        if ( typeof x === 'undefined' ) {
            x = this.renderedPosition.x;
        }
        const screenSize: ScreenSize = { ...screen.getScreenSize() };
        let proportion = ( screenSize.half.width - x ) / screenSize.half.width;
        if ( Math.abs( proportion ) > 1 ) {
            proportion = proportion / proportion;
        }
        return proportion;
    }

    /**
     * @description Gets which rotation should this instance have, accordingly to the camera.
     * @param { Camera } camera Unique Scene instance's camera.
     * @param { number } proportion (Optional) This instance's horizontal proportion, if already calculated. Otherwise will be calculated using the "camera" parameter.
     * @return { number } This instance's rotation, in degrees.
     */
    public getRotation( camera: Camera, proportion?: number ): number {
        if ( typeof proportion === 'undefined' ) {
            proportion = this.getProportionX();
        }
        return camera.getAngle() * proportion;
    }

    /**
     * @description Updates this instance's render data (primarly: position and rotation).
     * @param { Camera } camera Unique Scene instance's camera.
     */
    public update( camera: Camera ) {
        // Calculate the x render position
        const x = this.position.x + camera.x;
        // Get the impact of the rendered x on the screen width, to calculate the simulated 3D rotation and z for this item
        const proportion: number = this.getProportionX( x );
        // Calculate which rotation the item should have to simulate a 3D semi-spheric environment
        const rotation = this.getRotation( camera, proportion );
        // Calculate the y render position
        const y = this.position.y + camera.y;
        // Calculate a valid item width, because it could be null when the width is "auto" in the retrieved data
        let width: number = 0;
        if ( this.size.width === null ) {
            const element: any = document.querySelector( '#item-' + this.id );
            if ( element !== null ) {
                width = element.offsetWidth;
            }
        } else {
            width = this.size.width;
        }
        // Calculate a delta for the Z axis, because the rotation is performed from the item's center.
        // That will cause a small loss in the Z axis for this item.
        const delta =
            ( Math.sin( degsToRads( Math.abs(rotation) ) ) )
            * width / 2
            / ( 1 - Math.abs( proportion ) );
        // Calculate the z render position adding the calculated delta to the result
        const z = this.position.z + ( 1 - Math.cos( degsToRads(rotation) ) ) * width + delta;
        // Apply the calculated position to the rendered item
        this.renderedPosition = {
            x, y, z
        };
        // Apply the calculated rotation to the rendered item
        this.renderedRotation = {
            x: this.rotation.x,
            y: rotation + this.rotation.y,
            z: this.rotation.z
        };
        // Calculate if the current instance is visible by the given camera
        this.visible = this.isVisible( camera );
    }
}
